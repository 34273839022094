import React from "react";
import AppContainer from "../components/AppContainer";
import {
  Button,
  Grid,
  Container,
  Responsive,
  Icon,
  Header,
  Image,
  Divider} from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./Home.css";

import logo from "./logo.jpg"
import counselor from "./counselor.jpg";
import activities from "./activities.jpg";
import mooney from "./mooney.jpg";
import verbrugghe from "./verbrugghe.jpg";
import wilson from "./wilson.jpg";

const HomeHeader = () => (
    <div className="Header-content">
      <img alt="header here" src={logo} className="Header-logo" />
      <h1>Briarwood Running Camp</h1>
      <h2>Training for the Long Run!</h2>
      <div>
        <Button
          color="orange"
          onClick={() => window.open(process.env.REACT_APP_BROCHURE, "_blank")}
          size="medium"
          style={{ marginRight: "10px" }}
        >
          Brochure
        </Button>
        <Button
          color="orange"
          size="medium"
          style={{ marginLeft: "10px" }}
          as={Link}
          to="/Register"
        >
          Register
        </Button>
      </div>
    </div>
);

const InfoGrid = () => (
    <Container
      fluid className="Info-grid">
      <Grid stackable>
        <Grid.Row columns={4}>
          <Grid.Column textAlign="center" >
            <Icon size="huge" name="question" color="orange" style={{ marginBottom: "10px" }}/>
            <br/>
            An enjoyable learning experience for high school age runners of all abilities. The camp is big enough for FUN, but intentionally small enough for every camper to get personalized instruction.
          </Grid.Column>
          <Grid.Column textAlign="center" >
            <Icon size="huge" name="clock" color="orange" style={{ marginBottom: "10px" }}/>
            <br />
            One camp will be held in 2024.  A camper can either stay for the full
            week or just the weekend.
          </Grid.Column>
          <Grid.Column textAlign="center" >
            <Icon size="huge" name="map" color="orange" style={{ marginBottom: "10px" }}/>
            <br />
            The camp is located on a 30-acre facility in scenic Bucks County, Pennsylvania. The camp is located 10 miles north of Philadelphia and only 90 miles south of New York City.
          </Grid.Column>
          <Grid.Column textAlign="center" >
            <Icon size="huge" name="pencil" color="orange" style={{ marginBottom: "10px" }}/>
            <br />
            Register online or complete the application form included in the BROCURES and mail together with the specified deposit. Space for both camps is limited to 130.
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
);

const AboutGrid = () => (
  <Container textAlign="center" className="About-grid">
    <Grid stackable >

      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as="h1" >About Us</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          Founded by <a href="http://www.tedsilary.com/fredrosenfeld.htm" target="_blank" rel="noopener noreferrer">Fred Rosenfeld</a>, Briarwood Running Camp is designed to be an enjoyable learning experience for high school age runners of all abilities. The camp is big enough (approximately 130 campers) for lots of FUN, but intentionally small enough for every camper to get personalized instruction from some of the best coaches and athletes in the area, if not the country!
          <br/><br />
          We will have excellent guest speakers and small group discussions led by our outstanding staff members from around the country. (Olympic Trials qualifiers, NCAA All-Americans, sub 4:00 milers).
        </Grid.Column>
        <Grid.Column>
          <Image src={counselor}></Image>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <Divider color="orange" section as="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as="h1" >Running Activities</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Image src={activities}></Image>
        </Grid.Column>
        <Grid.Column textAlign="left">
          <Header as="h4" >Video Form Analysis</Header>
          <p>Some of the best coaches in the area will provide cutting-edge video analysis to help runners work on improving their running form.</p>
          <Header as="h4" >Great Running Opportunities</Header>
          <p>Some of the best coaches in the area will provide cutting-edge video analysis to help runners work on improving their running form.</p>
          <Header as="h4" >Learning By Doing</Header>
          <p>Some of the best coaches in the area will provide cutting-edge video analysis to help runners work on improving their running form.</p>
          <Header as="h4" >Daily Small Group Discussion</Header>
          <p>Daily, 30-minute discussions will focus on topics such as mental preparation, nutrition and injury prevention. Athletes will be able to choose which talks they attend.</p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <Divider color="orange" section as="div" />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <Header as="h1" >Coach Testimonials</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3} textAlign="center">
        <Grid.Column>
            <Icon name="quote left" color="orange" />
            <p>Ridge HS has attended Briarwood every year since it started and always had a positive experience. The staff produced a productive environment for high school runners to learn, train and appreciate what the sport has to offer. I always know that our team is in good hands, improving and having fun while they are at Briarwood.</p>
            <Responsive maxWidth={Responsive.onlyTablet.minWidth}>
              <h3>- Tim Mooney</h3>
              <p><i>Head Boys and Girls XC Coach, Ridge HS, NJ</i></p>
            </Responsive>
        </Grid.Column>
        <Grid.Column >
            <Icon name="quote left" color="orange" />
            <p>For many years I have been sending runners to Briarwood to learn all aspects of the sport of running – nutrition, injury prevention, form and style. Along the way, they laugh and they swim and they learn a little bit about themselves and about life. But, as a coach, I always look forward to their return, because they come back as a team… united and focused, with one common goal.</p>
            <Responsive maxWidth={Responsive.onlyTablet.minWidth}>
              <h3>- Kevin M. Verbrugghe</h3>
              <p><i>Former Head XC Coach, Roman Catholic HS</i></p>
            </Responsive>
        </Grid.Column>
        <Grid.Column >
            <Icon name="quote left" color="orange" />
            <p>For the past six years, several members of the Gwynedd Mercy Girls Cross Country Team have attended the Briarwood Running Camp. As their coach, I have found this camp has truly contributed to the success we have achieved. When I asked the girls what they like about the camp, their response was the outstanding structure, running with groups of various levels, and lastly, that they had FUN.</p>
            <Responsive maxWidth={Responsive.onlyTablet.minWidth}>
              <h3>- Larry Wilson</h3>
              <p><i>Head XC Coach, Gwynedd Mercy Academy</i></p>
            </Responsive>
        </Grid.Column>
      </Grid.Row>
      <Responsive as={Grid.Row} columns={3} textAlign="center" minWidth={Responsive.onlyTablet.minWidth}>
        <Grid.Column>
            <Image src={mooney} circular centered></Image>
            <h3>- Tim Mooney</h3>
            <p><i>Head Boys and Girls XC Coach, Ridge HS, NJ</i></p>
        </Grid.Column>
        <Grid.Column >
            <Image src={verbrugghe} circular centered></Image>
            <h3>- Kevin M. Verbrugghe</h3>
            <p><i>Former Head XC Coach, Roman Catholic HS</i></p>
        </Grid.Column>
        <Grid.Column >
            <Image src={wilson} circular centered></Image>
            <h3>- Larry Wilson</h3>
            <p><i>Head XC Coach, Gwynedd Mercy Academy</i></p>
        </Grid.Column>
      </Responsive>

    </Grid>
  </Container>
);

const Home = () =>  {
  return (
    <AppContainer>
      <div className="Header-background">
        <HomeHeader />
      </div>
      <InfoGrid />
      <AboutGrid />
    </AppContainer>
  );
}

export default Home;