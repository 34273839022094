import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./home"
import Registration from "./registration";
import './App.css';

const App =  ()  => (
  <Router>
    <Switch>
      <Route exact path="/" ><Home /></Route>
      <Route exact path="/Register" ><Registration /></Route>
    </Switch>
  </Router>
);

export default App;
