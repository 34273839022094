import React, { useState } from "react";
import {
  Header,
  Button,
  Menu,
  Icon,
  Message,
  Container,
  Responsive } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";

import "./AppContainer.css";

const AppMenu = ({ showMessage, onDismiss }) =>  {
  return (
    <header>
      <div>
        <Menu
          inverted
          borderless
          size="large"
          fixed={showMessage ? "" : "top"}
          attached={showMessage ? "top" : false}
        >
          <Container>
            <Menu.Item header as={Link} to="/" >Briarwood Running Camp</Menu.Item>
            <Responsive as={Menu.Item} minWidth={Responsive.onlyTablet.minWidth}>
              <Icon name="calendar outline" />
              Full Week: Aug 16 - 21, Weekend: Aug 16 - 18
            </Responsive>
            <Menu.Menu position="right">
              <Responsive as={Menu.Item} minWidth={Responsive.onlyTablet.minWidth}>
                  Registration:
              </Responsive>
              <Menu.Item as={NavLink} to="/Register" exact>2024</Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
        {showMessage &&
          <Message
            attached="bottom"
            warning
            onDismiss={onDismiss}
          >
            <div style={{margin: "0 auto", width: "50%", textAlign: "center"}}>
              <Message.Header>
                Briarwood Running Camp has two options for 2024!
              </Message.Header>
              <Message.Content>Campers can stay for a full week (August 16 to 21) or the weekend (August 16 - 18)</Message.Content>
            </div>
          </Message>
        }
      </div>
    </header>
  );
}

const AppFooter = () =>
  <footer>
    <div>
      <Header as="h4" inverted >© Briarwood Running Camp</Header>
      <Button circular icon="twitter" as="a" href="https://twitter.com/briarwoodrc" target="_blank"></Button>
      <Button circular icon="instagram" as="a" href="https://www.instagram.com/briarwoodrunningcamp/" target="_blank"></Button>
      <Button circular icon="mail" as="a" href="mailto:info@briarwoodrunningcamp.com"></Button>
    </div>
  </footer>
;

const AppContainer = ({ children }) => {
  const [showMessage, setShowMessage] = useState(true);

  const onDismiss = () => setShowMessage(false);

  return (
    <div className="app-wrapper">
      <AppMenu showMessage={showMessage} onDismiss={onDismiss} />
      <div style={showMessage ? {paddingTop: "25px"} : {paddingTop: "60px"}}>
        {children}
      </div>
      <AppFooter />
    </div>
  );
};

export default AppContainer;